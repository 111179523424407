import './App.css';


const Recovery = () => {

    return (
        <>
            <div className='h-20'></div>
            <div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'>Recovery Agents</div>
            <section>
                <div className='container px-6 xl:px-0 xl:w-4/5 2xl:container mx-auto py-20 min-h-screen flex flex-col justify-center'>
                    <p className='text-2xl'>
                        List of authorised recovery agency(ies)/agent(s), resolution agency(ies)/agent(s), collection agency(ies)/agent(s):
                    </p>
                    <div className='overflow-x-auto w-full max-h-[50rem] overflow-y-scroll mt-16'>
                        <table className="table-auto recovery-agent ">
                            <thead>
                                <tr>
                                    <th className='lg:min-w-[250px] '>Sr.No</th>
                                    <th className='min-w-[250px]'>Name of agency</th>
                                    <th className='min-w-[300px]'>Address</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>A D J Consultants Private Limited</td>
                                    <td>334 A ,SECOND FLOOR MAHINDRA PARK,RANI BAGH NEW DELHI 110034</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>2</td>
                                    <td>Aanchal Agency</td>
                                    <td>HOUSE NO 140, TARASAHED DAGICHA BEHIND RAJAPETH POLICE STATION BANDERA ROAD AMRAVATI 444606</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Aandavar Associates</td>
                                    <td>120, UPSTAIRS,THAMBI KALI AMMAN KOILS STREET, PONNAMMAPET,SALEM-636001,TAMILNADU</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>4</td>
                                    <td>Aastha Collection</td>
                                    <td>SHOP NO.20,TULSI COMPLEX,BH RAILWAY STATION,NADIAD,387002</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Abhi Associates</td>
                                    <td>ICICI BANK LTD., SHOP NO. 17,18,18A 19, LEKHRAJ KHAJANA COMPLEX, PLOT NO. D 2, SECTOR 4, INDIRA NAGAR, FAIZABAD ROAD, LUCKNOW, UTTAR PRADESH.226016</td>
                                </tr>

                                <tr className='bg-alt'>
                                    <td>6</td>
                                    <td>ABHIKSHA ASSOCIATES</td>
                                    <td>HATIGAON CHARIALI , NEAR HIGH SCHOOL , BHISHNOJYOTI PATH, PIN-781038 </td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>ACHIEVERS ASSOCIATE</td>
                                    <td>12-13-677\77 STREET NO 1 , KIMTEE COLONY, TARNAKA, SECUNDERABAD TS-500017</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>8</td>
                                    <td>Agile Trading</td>
                                    <td>HOUSE NO -96, 1ST FLOOR DILIP HUJURI PATH,SARUMOTORIA. GUWAHATI 781005</td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>Akriti enterprises</td>
                                    <td>KAILASH PARK,  MANORAMAGANJ,INDORE, MADHYA PRADESH,PIN - 452 001.</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>10</td>
                                    <td>AMBITIOUS ENTERPRISE</td>
                                    <td>VILL- PORSA, P.0+P.S- ITAHAR, DIST- UTTAR DINAJPUR, PIN- 733128</td>
                                </tr>
                                <tr>
                                    <td>11</td>
                                    <td>Anu Management And E-Services</td>
                                    <td>114 MAHATMA GANDHI ROAD, BANGALORE 560001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>12</td>
                                    <td>ARYAN ASSOCIATES</td>
                                    <td>BIJAWAR NAKA,SAGAR ROAD CHHATARPUR MADHYA PRADESH 471001</td>
                                </tr>
                                <tr>
                                    <td>13</td>
                                    <td>AS ASSOCIATES</td>
                                    <td>NO 251, 2ND FLOOR APARNA BUILDING, 17TH CROSS, SAMPIGE ROAD, MALLESHWARAM, BANGALORE - 560003</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>14</td>
                                    <td>B.k Associates</td>
                                    <td>RUSTAMPUR TIGRI, POST LALUWALA DISTT MORADABAD (U.P) 244402.</td>
                                </tr>
                                <tr>
                                    <td>15</td>
                                    <td>Bankey Bihari Associate</td>
                                    <td>Vasundhara Colony ,Opp. Suraj Dharam Kanta Mathura Road ,Sasni Gate Aligarh- 202001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>16</td>
                                    <td>BHA Financial solution</td>
                                    <td>24 GURUKUL COLONY RANGWAS SQUARE CAT ROAD RAU INDORE 453331</td>
                                </tr>
                                <tr>
                                    <td>17</td>
                                    <td>BHAGAT ASSOCIATES </td>
                                    <td>LIBERLY COMPLEX OPP BIJAPUR HOSPITAL ARVIND NAGAR KARWAR KARNATAKA 580024</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>18</td>
                                    <td>BHAGWATI CREDIT SOLUTIONS</td>
                                    <td>A-44 IIND FLOOR, AMAR COMPLEX, BHAGWATI GARDEN UTTAM NAGAR NEW DELHI-110059</td>
                                </tr>
                                <tr>
                                    <td>19</td>
                                    <td>Bhatiya Associates</td>
                                    <td>Bhatiya associates..SHop no.G-25,322 Samriddhi Compelax Near Tower square indore..Pin code..452001 </td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>20</td>
                                    <td>BHUPINDER ASSOCIATES</td>
                                    <td>PLOT B19 65 SE THE MALL LGF 1 1ST MALL LUDHIANA PUNJAB 141001</td>
                                </tr>
                                <tr>
                                    <td>21</td>
                                    <td>BIGBULL MANAGEMENT SERVICES</td>
                                    <td>JADHUA MARKET MAHADEV COMPLEX JADHUA HAJIPUR VAISHALI BIHAR 844101</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>22</td>
                                    <td>Bm Credit Solutions Llp</td>
                                    <td>NANGAL, RAYA BRANCH, DELHI</td>
                                </tr>
                                <tr>
                                    <td>23</td>
                                    <td>Bright Fino Tech Services LLP</td>
                                    <td>SNO-25/4A/4, NAVECHA ROAD, PIMPLE GAURAV ROAD, SHREE SAINATH NAGAR, PIMPLE GURAV, PIMPRI CHINCHWAD, PUNE, MAHARASHTRA, 411061</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>24</td>
                                    <td>CEDAR BUSINESS SOLUTION</td>
                                    <td>NO.7/3,2ND FLOOR,SUBBARAYA STREET,SHENOY NAGAR,CHENNAI-600030</td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                    <td>CHOUDHARY CONSULTANCY</td>
                                    <td>GROUND FLOOR, TANWAR PALAZA,OLD DELHI ROAD,NEAR OBC BANK, SHAHAPUR,JAIPUR,RAJ 303103</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>26</td>
                                    <td>Citi Enterprises</td>
                                    <td>SECTOR -40, CHANDIGARH - 160036</td>
                                </tr>
                                <tr>
                                    <td>27</td>
                                    <td>Collekto</td>
                                    <td>9TH FLOOR . UNIT 905, FENKIN 9 , NEAR SATKAR GRAND HOTEL, WAGLE ESTATE, THANE : 400604 </td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>28</td>
                                    <td>Credexa Business Solutions Private limited</td>
                                    <td>OFFICE NO.403, RUPA SOLITIARE, BLDG. A-1, MILLENNIUM BUSINESS PARK, SECTOR-2, MAHAPE, NAVI MUMBAI- 400710</td>
                                </tr>
                                <tr>
                                    <td>29</td>
                                    <td>Credit Revive Financial Services</td>
                                    <td>1114/A,ROYAL COURTS,OFFICE NO.O-16,SHAHUPURI,WARD,KOLHAPUR 416001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>30</td>
                                    <td>CREDIT SOLUTIONS</td>
                                    <td>PANIHATI MUNICIPALITY,B. T. ROAD, PANIHATI  NORTH 24 PARGANAS</td>
                                </tr>
                                <tr>
                                    <td>31</td>
                                    <td>Credit Spread Management </td>
                                    <td>196/7CBQ,STREET NO 7.THAN SINGH NAGAR,ANAND PARBAT NEW DELHI 110005</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>32</td>
                                    <td>CSR ASSOCIATES</td>
                                    <td>#67, 1ST FLOOR, 3RD MAIN ROAD, 5TH CROSS, CHAMRAJPET BANGALORE 560018</td>
                                </tr>
                                <tr>
                                    <td>33</td>
                                    <td>DABLA BROTHERS PVT. LTD.</td>
                                    <td>B-26 27, MAHALAXMI NAGAR,JLN MARG, JAIPUR-302017</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>34</td>
                                    <td>Daksha Services</td>
                                    <td>1866 E WARD,RAJARAMPURI,7TH LANE,KOLHAPUR-416008</td>
                                </tr>
                                <tr>
                                    <td>35</td>
                                    <td>DEBT CARE ENTERPRISES PVT LTD</td>
                                    <td>104 B SNEHAPRABHA APARTMENTS NEW BOWENPALLY SECUNDERABAD 500011</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>36</td>
                                    <td>DHAATA FORCE</td>
                                    <td>OFFICE NO 64 VALLUVAR SALAI ARUMBAKKAM CHENNAI TAMIL NADU 600106</td>
                                </tr>
                                <tr>
                                    <td>37</td>
                                    <td>Divine Associates</td>
                                    <td>ICICI BANK LTD., SCF 19, PHASE 1, URBAN ESTATE, DUGRI, LUDHIANA, LUDHIANA DIST., PUNJAB.141013</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>38</td>
                                    <td>DVB ASSOCIATES</td>
                                    <td>18-434/1,PLOT NO 112,MALLIKARJUNA NAGAR, MALKAJGIRI,SECUNDERABAD,TS-500047</td>
                                </tr>
                                <tr>
                                    <td>39</td>
                                    <td>Eagle services</td>
                                    <td>402 SAFFRON COMPLEX FATEHGUNJ CIRCLE FATEHGUNJBARODA 390002</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>40</td>
                                    <td>EERA ASSOCIATES</td>
                                    <td>NARMADAPURAM BRANCH, GROUND FLOOR, ANAND NAGAR</td>
                                </tr>
                                <tr>
                                    <td>41</td>
                                    <td>EMERALD ASSOCIATES</td>
                                    <td>NO 14/17 SOWRASHTRA NAGAR 1ST STREET CHOOLAIMEDU NUNGAMBAKKAM CHENNAI 600094</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>42</td>
                                    <td>EXON TECHNOLOGIES</td>
                                    <td>NO-7A, NEW NO-12/1, JHANSI PLAZA, 2ND FLOOR, NEW AVADI ROAD, VILLIVAKKAM,CHENNAI-600049.</td>
                                </tr>
                                <tr>
                                    <td>43</td>
                                    <td>FINANCIAL SOLUTIONS</td>
                                    <td>KHASRA NO.936, AL-REHMAN SHOPPING COMPLEX, HARI SINGH HIGH STREET, SRINAGAR SRINAGAR JAMMU AND KASHMIR 190001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>44</td>
                                    <td>FORTE MANAGEMENT SERVICES</td>
                                    <td>BALI TOWERS NO 1 ABDUL RAZACK STREET, SAIDAPET, CHENNAI-600015</td>
                                </tr>
                                <tr>
                                    <td>45</td>
                                    <td>GANASRI ENTERPRISES</td>
                                    <td>#27, H R RESIDENCY, JANATHA COLONY, JAKKASANDRA 1ST MAIN ROAD, KORAMANGALA 1ST BLOCK, BANGALORE-560034.</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>46</td>
                                    <td>GMR ENTERPRISES</td>
                                    <td>8-3-214/53/A/1,2 ND FLOOR,SRINIVASACOLONY WEST,AMEERPET,HYD-500038</td>
                                </tr>
                                <tr>
                                    <td>47</td>
                                    <td>Harishitha</td>
                                    <td>42,5TH CROSS 5TH MAIN JAYANAGAE MYSORE 570014</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>48</td>
                                    <td>HARSHIVI ENTERPRISES</td>
                                    <td>NEAR HOLI CHOWK UPPER G/F A-47 HARI VIHAR UTTAM NAGAR HASTSAL VIHAR NEW DELHI 110059 </td>
                                </tr>
                                <tr>
                                    <td>49</td>
                                    <td>HIRANYA CAPITALS</td>
                                    <td>PLOT NO3681, 2ND FLOOR,TNHB MAIN ROAD,KAKKALUR,THIRUVALLUR -602001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>50</td>
                                    <td>Hi-Tek Syndicate</td>
                                    <td>J-3VIKASPURI  NEW DELHI PHONE 28542393,28542404</td>
                                </tr>
                                <tr>
                                    <td>51</td>
                                    <td>HOPES FIN CON</td>
                                    <td>3rd FLOOR REGENT COURT IYYATTILMUKKU CHITTOR ROAD KOCHI KERALA 682011</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>52</td>
                                    <td>ICREDIZEN FINANCIAL TECHNOLOGIES PRIVATE LTD</td>
                                    <td>DR NO.2-64/2/A,PLOT NO.20 & 21 FOURTH FLOOR,RAGHU TOWERS MEGHA HILLS ROAD 2 MADHAPUR HYDERABAD TELANGANA INDIA 500081</td>
                                </tr>
                                <tr>
                                    <td>53</td>
                                    <td>IG TECHSERV PVT LTD</td>
                                    <td>B-5,6,7, H-73, SEC-63, NOIDA U.P. IN</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>54</td>
                                    <td>INTERNAL POWER SERVICES</td>
                                    <td>SAI NATH NAGAR H-104 C -SECTOR BHOPAL</td>
                                </tr>
                                <tr>
                                    <td>55</td>
                                    <td>JAI MAA SWAROOP ENTERPRISES</td>
                                    <td>12-B JAI KARNI NAGAR NIWARU ROAD ,NR. AMBEY HOSPITAL NIWARU ROAD JHOTWARA ,JAIPUR- 302012</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>56</td>
                                    <td>Jai Shree Maruti Recovery </td>
                                    <td>PLOT NO 3 SECROR NO 2 ARIHANT PARISAR RATLAM 457001</td>
                                </tr>
                                <tr>
                                    <td>57</td>
                                    <td>Jbms Associates</td>
                                    <td>350/351, Pocket -1 Sec A-06, DDA Flat,  Second Floor. Jagriti Apartment, Narela Delhi 110040. </td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>58</td>
                                    <td>KAKSHI ASSOCIATES</td>
                                    <td>MDR ROAD RAIL WAY GATE NO 2 NEW GUWAHATI,NOONMATI GUWAHATI-781020( ASSAM)</td>
                                </tr>
                                <tr>
                                    <td>59</td>
                                    <td>Kaushik Construction</td>
                                    <td>Kaushik Construction Mohalla dilajak badlapur padav pin 222002</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>60</td>
                                    <td>Keerthi Assocaites</td>
                                    <td>T6-C 18,3RD FLOOR,SRIRAM TOWERS,3RD CROSS,WEST THILLAI NAGAR TRICHY 620018</td>
                                </tr>
                                <tr>
                                    <td>61</td>
                                    <td>KHAN INTERPRISES AND RECOVERY</td>
                                    <td>ward no2 behind kurwai post office vidisha mp 464224</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>62</td>
                                    <td>KHYATI ASSOCIATES </td>
                                    <td>Ward No. 14, Patandev Sagar Road, Raisen, [M.P.] PIN CODE: 464551</td>
                                </tr>
                                <tr>
                                    <td>63</td>
                                    <td>KP Services</td>
                                    <td>210/A,TIRTHRAJ COMPLEX BEHIND HASUBHAI CHAMNERS MADALPUR ELLISBRIDGE AHMEDABAD 380006</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>64</td>
                                    <td>Krishiv Collection Agency</td>
                                    <td>SHOP NO 21 MARRY GOLD COMPLEX NEAR ICICIBANK MOTIBAUG JUNAGADH 362001</td>
                                </tr>
                                <tr>
                                    <td>65</td>
                                    <td>Krishna Services</td>
                                    <td>3 opprosite csd canteen alwar road behror alwar 301701</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>66</td>
                                    <td>KS VENTURE</td>
                                    <td>Jonali Path, house number 2Ambikagirinagar, Guwahati</td>
                                </tr>
                                <tr>
                                    <td>67</td>
                                    <td>KUSUM ENTERPRISE</td>
                                    <td>ARUN GOLAP BHAWAN,HOUSE NO 8,3RD FLOOR,NILOMONI PHUKAN PATH,CHRISTIAN BASTI GS ROAD GUWAHATI 781005</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>68</td>
                                    <td>Lane Consultancy</td>
                                    <td>No-67, 2nd Floor, Madhavaram High Road, Perambur, Chennai, Tamil Nadu 600011</td>
                                </tr>
                                <tr>
                                    <td>69</td>
                                    <td>LK Associates</td>
                                    <td>No.53/1, Plot No.21,Thirukkai Avenue Street,Kaliamman Koil Street,Virugambakkam, Chennai – 600 092</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>70</td>
                                    <td>M.H.COLLECTIONS</td>
                                    <td>APEEJAY HOUSE,15, PARK STREET,KOLKATA - 700 016</td>
                                </tr>
                                <tr >
                                    <td>71</td>
                                    <td>MAA BHAWANI ENTERPRISES</td>
                                    <td>Plot No. 233 Daata Sadan,Opp Rajasthan Patrika Office Maan Ji Ka Hatha, Paota,Jodhpur.</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>72</td>
                                    <td>MAA SHARDAKRIPA ASSOCIATES</td>
                                    <td>1ST FLOOR, GARDEN HOMES,BEHIND SUNIL HOSIERY,NEPIER TOWN,NEAR BHAWARTAL GARDEN JABALPUR 482001</td>
                                </tr>
                                <tr >
                                    <td>73</td>
                                    <td>MAHAVEER CONSULTANCY</td>
                                    <td>PLOT NO-1123/3867,RABINDRA GARDEN,SOUBHAGYA NAGAR,BARAMUNDA,BHUBANESWAR 751001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>74</td>
                                    <td>MAHI ASSOCIATES</td>
                                    <td>A 301 INDRIA CHAMBERS, SADASHIV PETH, TILAK ROAD, PUNE 41130</td>
                                </tr>
                                <tr >
                                    <td>75</td>
                                    <td>Mangalam Associates</td>
                                    <td>Manglam associates..204,West end corporate Building new palasiya Indore..Pin code..452010</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>76</td>
                                    <td>Manglam Associates</td>
                                    <td>second Floor, 204, WEST AND CORPORATE, NEW PALASIA, Indore Indore, Madhya Pradesh, 452001</td>
                                </tr>
                                <tr >
                                    <td>77</td>
                                    <td>Mark Enterprises</td>
                                    <td>705 /709 7th Floor Topiwala Center Goregaon (W) 400062</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>78</td>
                                    <td>MARUTI ASSOCIATES</td>
                                    <td>Japorigog, Karbi Namghar Path, H No.57, Guwahati-781005, Assam</td>
                                </tr>
                                <tr >
                                    <td>79</td>
                                    <td>Maruti Nandan</td>
                                    <td>S/61 2ND FLOOR, AMEE ARCADE, OPP NEW BUS STAND, GODHRA 389001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>80</td>
                                    <td>MAX FINANCIAL SERVICES</td>
                                    <td>C-109,11 B CROSS,WEST THILLAI NAGAR,TRICHY-620018</td>
                                </tr>
                                <tr >
                                    <td>81</td>
                                    <td>Moira Associates</td>
                                    <td>D.NO.46-20-87,MANDAVARI STREET.DONDAPARTHY,VISAKHAPATNAM-530016</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>82</td>
                                    <td>NANDA FINANCIAL SERVISES</td>
                                    <td>H.NO 336,ROHTA ROAD,ANOOP NAGAR FAZALPUR, MEERUT 250001</td>
                                </tr>
                                <tr >
                                    <td>83</td>
                                    <td>NAVRAJ COMMUNICATION SERVICES LLP</td>
                                    <td>SR NO 85/1/1C SNEHADEEP COMPLEX 3RD FLOOR OFFICE NO 304 NEAR WARJE FLYOVER PUNE MAHARASHTRA 411058</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>84</td>
                                    <td>NEERAJ ASSOCIATES</td>
                                    <td>42,43 Sumer Enclave Bus Stand Watika,  Sanganer Jaipur Rajasthan 303905</td>
                                </tr>
                                <tr >
                                    <td>85</td>
                                    <td>NEXGEAR INFOTECH</td>
                                    <td>203/204,Pahlaj Kunj,Lohar Ali,Chendani Road,Thane w 400 601</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>86</td>
                                    <td>NN ASSOCIATES</td>
                                    <td>#2773, SANTHE MAIDHANA KOLAR MAIN ROAD Bangarpet-563114</td>
                                </tr>
                                <tr>
                                    <td>87</td>
                                    <td>OASIS CREDIT MANAGEMENT</td>
                                    <td>NO.3 VILLAGE ROAD NUNGAMBAKKAM, 'CHENNAI - 600 034</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>88</td>
                                    <td>OK.Credit collection</td>
                                    <td>NEAR ICON PLAZA GOBARSAHI MUZZAFFARPUR BIHAR 842001</td>
                                </tr>
                                <tr >
                                    <td>89</td>
                                    <td>Om sai Services</td>
                                    <td>4TH Floor, Block 554, Nr.Agarwal Chamber, Opp.Town Hall, Ellis bridge,</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>90</td>
                                    <td>PHOENIX MANAGAMENT SERVICES</td>
                                    <td>PLOT NO 4 1ST FLOOR RANGANATHAN NAGAR NORTH KORATTUR CHENNAI 600076</td>
                                </tr>
                                <tr >
                                    <td>91</td>
                                    <td>Pillars India</td>
                                    <td>OFFICE NO 203 FATIMA COMPLEX BALMIKIMARG NEAR ALLAHABAD BANK LALBAGH LUCKNOW 226601</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>92</td>
                                    <td>Pinnacle advisory</td>
                                    <td>PLOT NO 05 KURUD ROAD INFRONT OF MANSA COLLAGE BHILAI DIST C.G 490023</td>
                                </tr>
                                <tr >
                                    <td>93</td>
                                    <td>POONIA ASSOCIATES PVT LTD</td>
                                    <td>KOTAK MAHINDRA BANK LTD GROUND FLOOR, PLOT NO. 406 TO 408/28 ROOPALAY COMPLEX, MODEL TOWN ROHTAK, HARIYANA-124001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>94</td>
                                    <td>Prachi Enterprises</td>
                                    <td>Bypass Ashta Near DCB Bank Ashta Sehore,Madhyapradesh 466116</td>
                                </tr>
                                <tr >
                                    <td>95</td>
                                    <td>PRANAVAM  CREDIT MANAGEMENT PVT LTD.</td>
                                    <td>DOOR NO.1/3714C1, 2ND  FLOOR, CITY CORNER BUILDING NADAKKAVU, CALICUT 673011</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>96</td>
                                    <td>Probiz Recovery Assocites Private Limited</td>
                                    <td>nfront of Ma Purnagiri Guest House Shringar Nagar, Nidhauli Road, Etah- UP 207001</td>
                                </tr>
                                <tr >
                                    <td>97</td>
                                    <td>Progresseeve feentech</td>
                                    <td>A 1 New Indira Co-op Society Near Union Bank of India Shastri Nagar Yerawada Pune 411006</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>98</td>
                                    <td>RAAMDHENU ENTERPRISE</td>
                                    <td>BOIRAGIMOTH KOCHARI GOAN DIBRUGARH ASSAM 786003</td>
                                </tr>
                                <tr >
                                    <td>99</td>
                                    <td>RANA CORPORATION</td>
                                    <td>113,114 hotel satkar building unapani road delhigate surat-395003</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>100</td>
                                    <td>Ratna agency</td>
                                    <td>305 /RUDRAMAL COMPLEX OPP AMOLA PG HOUSE NEAR GANESH PLAZA NAVA RANPURA POST OFFICE AHMEDABAD 380009</td>
                                </tr>
                                <tr >
                                    <td>101</td>
                                    <td>Ridhi Sidhi Associates</td>
                                    <td>4DIAMOND COLONY NEW PALASIYA INDORE MP 452001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>102</td>
                                    <td>RM Associates</td>
                                    <td>16-11-511/D/21/4, SHALIVAHANA NAGAR DILSUKHNAGAR HYDERABAD-500036</td>
                                </tr>
                                <tr >
                                    <td>103</td>
                                    <td>RM SERVICE</td>
                                    <td>Plot no-3214,Room No-103,1st floor,Maruti Bhaban,Kotitirtha Lane,Old Town,Bhubaneswar,khorda,Odisa-751002</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>104</td>
                                    <td>Romit akaay Associates</td>
                                    <td>Office no. 503 5th floor 78 business park (bmc market bldg) station road bhandup west mumbai 400078</td>
                                </tr>
                                <tr >
                                    <td>105</td>
                                    <td>Roshan Management</td>
                                    <td>3B1 SOLAI COMPLEX 1ST FLOOR MAHAVEER GARDEN ANUSHA STREET AMBATTUR CHENNAI 600058</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>106</td>
                                    <td>Royal Baba Dabas</td>
                                    <td>PLOT NO C1-48-49, UPPER GROUND FLOOR , FLAT NO.001, SURAJ TOWER, DEEP VIHAR , OPP SEC 24 , ROHINI , DELHI – 110085</td>
                                </tr>
                                <tr >
                                    <td>107</td>
                                    <td>Rudra Finance</td>
                                    <td>Pimpri Chowk, Pune 411017</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>108</td>
                                    <td>Rudraksh Associates</td>
                                    <td>N3/75-B-6 R K PURAM COLONY KARMAJEETPUR SUNDERPUR VARANASI 221005 UP</td>
                                </tr>
                                <tr >
                                    <td>109</td>
                                    <td>SA CORPORATE</td>
                                    <td>BEHALA, 7 BRAMHASAMAJ ROAD 700034</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>110</td>
                                    <td>SADASHIV ASSOCIATES</td>
                                    <td>Choudhary Complex, Rajendra Chowk, Bihar 800016</td>
                                </tr>
                                <tr >
                                    <td>111</td>
                                    <td>SAI CHERISH FINANCIAL SERVICES</td>
                                    <td>Ground floor ,Plot bearing no-83 and 84,Punnaiah Plaza, Road no 2 Banjara Hills, Near Jubilee Hills Check Post,Hyderabad,Telangana State, Pin code-500 033</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>112</td>
                                    <td>SAI KRIPA ASSOCIATES</td>
                                    <td>PLOT NO 228 MEZZANINE FLOOR ABOVE BANK OF INDIA 462011 MP NAGAR ZONE 1 BHOPAL MP 462011</td>
                                </tr>
                                <tr >
                                    <td>113</td>
                                    <td>SAPTAGIRI ASSOCIATES</td>
                                    <td>23-88/8 R K NAGAR ANANDBAGH X ROADS MALKAJGIRI 500047</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>114</td>
                                    <td>SARAH AGENCY</td>
                                    <td>NO 24/2 KHAJA COMPLEX 1ST FLOOR, RAJENDRA ROAD NEAR RAILWAY STATION UDUMALPET 642126 TIRUPPUR TAMILNADU</td>
                                </tr>
                                <tr >
                                    <td>115</td>
                                    <td>SATYAM AGENCY</td>
                                    <td>U-6 KUBER COMPEX NEAR KOTAK MAHINDRA BANK DR YAGNIK ROAD RAJKOT GUJRAT 360001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>116</td>
                                    <td>SHARDA ENTERPRISES</td>
                                    <td>C-89 STATION PLAZA,STATION ROAD,BHANDUP WEST-400078</td>
                                </tr>
                                <tr >
                                    <td>117</td>
                                    <td>SHARMISHTA ENTERPRISES</td>
                                    <td>LIG 285 A SECTOR SONAGIRI INFRONT DURGA MANDIR PIPLANI BHOPAL 462022</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>118</td>
                                    <td>Shiv Associates</td>
                                    <td>53/4-A, BHAG KAMBAL WALA,JANSATH ROAD,NEW MANDIMUZZAFAR NAGAR UTTAR PRADESH 251001</td>
                                </tr>
                                <tr >
                                    <td>119</td>
                                    <td>Shiv Shakti Associates</td>
                                    <td>1/56 D Top Floor, Lalita Park, Near by gurudwara Laxmi Nagar East Delhi-110092</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>120</td>
                                    <td>Shiv Shakti Enterprises</td>
                                    <td>1/56 D Top Floor, Lalita Park, Near by gurudwara Laxmi Nagar East Delhi-110092</td>
                                </tr>
                                <tr >
                                    <td>121</td>
                                    <td>SHREE BALAJI MANAGEMENT</td>
                                    <td>(GC 98 1st FLOOR PULL PRALHADPUR NEAR RAM CHANDER MEMORIAL SCHOOL NEW DELHI 110044</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>122</td>
                                    <td>SHREE GANESH AGENCY</td>
                                    <td>FLAT NO 202 2 ND FLOOR PARISHRAM APARTMENT KUKADE LAYOUT RAMESHWARI ROAD NEAR CHANDRAMANI NAGAR NIT GARDEN BABULKHEDA NAGPUR 440027</td>
                                </tr>
                                <tr >
                                    <td>123</td>
                                    <td>Shree Mahadevi Associates</td>
                                    <td>AC-167C, Shalimar Bagh, New Delhi-110088</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>124</td>
                                    <td>SHREE MARUTI ASSOCIATES</td>
                                    <td>Shree Maruti Recovery Agency...161,Biriyakheda Ratlam M.P. Pincode...457001</td>
                                </tr>
                                <tr >
                                    <td>125</td>
                                    <td>SHREE OMKAR ASSOCIATE</td>
                                    <td>KAMALA MILLS COMPOUNDSENAPATI BAPAT MARG,LOWER PARELMUMBAI MAHARASHTRA 400013</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>126</td>
                                    <td>Shree Ramehskrupa Solution</td>
                                    <td>OFFICE NO 504 5TH FLOOR WEST GATE 2 BUILDING AYODHYA CHOWK 150 FEET RING ROAD RAJKOT 360005</td>
                                </tr>
                                <tr >
                                    <td>127</td>
                                    <td>shree recovery agency</td>
                                    <td>OFFICE NO 2 WARD 9/B,PLOT NO 792,HARIHAR SOCIETY</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>128</td>
                                    <td>SHREE SAI ENTERPRISES</td>
                                    <td>PLOT NO 27 CHINTAMANI LAYOUT SHIVKRUPA NAGAR GHOGHALI ROAD BESA NAGPUR 550037</td>
                                </tr>
                                <tr >
                                    <td>129</td>
                                    <td>Shree Samartha Enterprises</td>
                                    <td>Shivaji Peth, Thane (West) - 400602</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>130</td>
                                    <td>Shree Shiv Shakti Associates</td>
                                    <td>ICICI Bank Ltd., Jayshree Plaza, Near Dreams Mall, L.B.S. Marg, Bhandup W Mumbai.400078</td>
                                </tr>
                                <tr >
                                    <td>131</td>
                                    <td>Shree Shyam Associate</td>
                                    <td>KHAIR ROAD NEAR KHAIRESHWAR CHAURAHA APPOSITE SBI BANK SHIVALIK MARKET,ALIGARH ,UTTAR PRADESH 202001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>132</td>
                                    <td>Shreeya Info Services</td>
                                    <td>K K MARKET OFFICE NO 58 B WING 6TH FLOOR BALAJI NAGAR DHANKAWADI PUNE 411043</td>
                                </tr>
                                <tr >
                                    <td>133</td>
                                    <td>SHRI KARNI ASSOCIATES</td>
                                    <td>Ground Floor, Shop No.46,Ostwal Plaza-II, Sundarwas , Udaipur (Raj.)313001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>134</td>
                                    <td>Shri Shyam Associates</td>
                                    <td>KHAIR ROAD NEAR KHAIRESHWAR CHAURAHA APPOSITE SBI BANK SHIVALIK MARKET,ALIGARH ,UTTAR PRADESH 202001</td>
                                </tr>
                                <tr >
                                    <td>135</td>
                                    <td>SHRI VINAYAK ASSOCIATES COLLECTION AGENCY</td>
                                    <td>GURUDWARA KE PASS KHEJRA ROAD CANTT GUNA MADHYA PRADESH 4730001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>136</td>
                                    <td>Shubham Debt Recovery Agency</td>
                                    <td>17L/13A/10K OM Nagar, Rajapur Prayagraj 211002 UP</td>
                                </tr>
                                <tr >
                                    <td>137</td>
                                    <td>Siddhi vinayak (Milan Tvs)</td>
                                    <td>307-3RD FLOOR OASIS TRADE CENTER ABOVE MILAN TVS Y N ROAD INDORE</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>138</td>
                                    <td>Siddhi vinayak associates (Milan Tvs)</td>
                                    <td>307-3RD FLOOR OASIS TRADE CENTER ABOVE MILAN TVS Y N ROAD INDORE</td>
                                </tr>
                                <tr >
                                    <td>139</td>
                                    <td>Siddhi Vinayak Enterprises</td>
                                    <td>202-203 2ND FLOOR ,METRO TOWER,VIJAY NAGAR INDORE 452010 MP</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>140</td>
                                    <td>SIDDHIVINAYAK SERVICES</td>
                                    <td>Sr. No. 13, Satavwadi, Hadapsar, Pune - 411028</td>
                                </tr>
                                <tr >
                                    <td>141</td>
                                    <td>SINGH ENTERPRISES</td>
                                    <td>G.T.ROAD, DHANIPUR, ALIGARH- 202001, UTTAR PRADESH</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>142</td>
                                    <td>SK Financial Services</td>
                                    <td>147, NIL, HASANRAJPUR, THARUWAPUR HARRAIYA, BASTI UTTAR PRADESH- 272129</td>
                                </tr>
                                <tr >
                                    <td>143</td>
                                    <td>Soniya Moter Serivce</td>
                                    <td>CHANDAUSI CHAURAHA SAMBHAL UP 244302</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>144</td>
                                    <td>SREE VISHNU</td>
                                    <td>NO.3 PALAT MADHAVAN STREET, MAHALINGAPURAM,NUNGAMBAKKAM,CHENNAI-600 034</td>
                                </tr>
                                <tr >
                                    <td>145</td>
                                    <td>SRI ASSOCIATES</td>
                                    <td>SRII ASSOCIATES # 214 15TH MAIN, 24 TH CROSS B S K 2ND STAGE BANGALORE 560070</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>146</td>
                                    <td>SRI KANAKA DURGA ASSOCIATES</td>
                                    <td>D NO 40-2-30/A,SRI SAI APARTMENTS</td>
                                </tr>
                                <tr >
                                    <td>147</td>
                                    <td>SRI LAKSHMI ASSOCIATES</td>
                                    <td>65-3-31A DWARKAMAI NILYAM NTR CIRCLE PATAMATA</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>148</td>
                                    <td>SRI LAKSHMI GANAPATHI SERVICES</td>
                                    <td>HNO 1-8-32&33 ,1ST FLOOR ,1ST PORTION TEMPLE ALWAL OPP PLOCE STATION SECUNDERABAD 500010 TELANGANA</td>
                                </tr>
                                <tr >
                                    <td>149</td>
                                    <td>Sriranga Associates</td>
                                    <td>No 40 SLN Nilaya 5th A Cross RHCS Layout Annapoorneshwari Nagara Nagarabhavi Bengaluru 560091</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>150</td>
                                    <td>SRIVASTAVA & SONS</td>
                                    <td>2ND FLOOR REKHI MANSION, DAIGONAL ROAD , BISTUPUR JAMSHEDPUR 831001, OPP HOTEL RAJHANS</td>
                                </tr>
                                <tr >
                                    <td>151</td>
                                    <td>SRM Associates</td>
                                    <td>SHOP NO 03,C-17 KUNJ VIHAR JAGRITI VIHAR MEERUT UP 250004</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>152</td>
                                    <td>SRS ADVISORY PVT LTD</td>
                                    <td>132/A SOUTHERN AVENUE,DR.MEGHNAD SAHA SARANIGOL PARK, DHAKURIA KOLKATA WEST BENGAL 700029</td>
                                </tr>
                                <tr >
                                    <td>153</td>
                                    <td>SSS Associates</td>
                                    <td>LOWER GROUND & FIRST FLOOR 340, MIG 80 FEET ROAD, ANNA NAGAR, MADURAI, 625020</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>154</td>
                                    <td>Star Management Services</td>
                                    <td>A-21,3RD FLOOR ST NUMBER 19 NEAR SINGLA RESTAURANT MADHU VIHAR 110092</td>
                                </tr>
                                <tr >
                                    <td>155</td>
                                    <td>Star Net Enterprises</td>
                                    <td>RASALPUR AURANGABAD MEERUT 250001 UTTARPRADESH</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>156</td>
                                    <td>STUNNING CORPORATE SOLUTIONS</td>
                                    <td>A-61 SEVEN WONDERS SECTOR-16 NOIDA 201301</td>
                                </tr>
                                <tr >
                                    <td>157</td>
                                    <td>SURE SAFE ADVISORY PVT LTD</td>
                                    <td>4 DR.SURESH SARKAR ROAD 3RD FLOOR ,AMANTRAN HOUSE KOLKATA-700014</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>158</td>
                                    <td>SV ENTERPRISES</td>
                                    <td>RUPAM CENTER CINE PLANET, MUMBAI</td>
                                </tr>
                                <tr >
                                    <td>159</td>
                                    <td>SVB ENTERPRISES</td>
                                    <td>#125/74/1 OPP TO AGARA LAKE, BESIDE VANDANA SAROVAR APTS, 6TH CROSS, 3RD MAIN ROAD, JAKKASANDRA , KORAMANGALA 1ST BLOCK, BANGALORE-560034</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>160</td>
                                    <td>SVS SOLUTIONS</td>
                                    <td>PLOT NO 4 PAIGHA HOUSING COLONY PHASE -2 BEGUMPET HYDERABAD,TELANGANA 500003</td>
                                </tr>
                                <tr >
                                    <td>161</td>
                                    <td>Swami Samarth Multi Services</td>
                                    <td>PLOT NO 8 A PRATIK PLAZA PAHADE CORNER GARKHEDA PARISAR AURANGABAD 431009</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>162</td>
                                    <td>T&T Associate</td>
                                    <td>AT  DHIPASAHI WARD NO 11, BARIPADA NAYUR BHANJ ODISHA 757001</td>
                                </tr>
                                <tr >
                                    <td>163</td>
                                    <td>Udan Enterprises</td>
                                    <td>BAJRANG SMRUTI APPT BUILDING NO 13, FLAT NO 05 NARHARI NAGAR,TAGORE NAGAR OPP AMBEDKAR NAGAR NASHIK 6 422006</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>164</td>
                                    <td>Union Debt Solutions</td>
                                    <td>OFFICE NO-11,2ND FLOOR BANALI APARTMENT NEAR NAL STOP CHOWK ERANDWANE PUNE 411004</td>
                                </tr>
                                <tr >
                                    <td>165</td>
                                    <td>Unique Financial Services</td>
                                    <td>PLOT NO 54 P&T COLONY TIRUMALGIRI SECUNDERBAD 500015</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>166</td>
                                    <td>United Associates</td>
                                    <td>GAYAKWAD COMPLEX AMARKANTAK ROAD DHANPURI RESINAL BURHAR DIST SAHDOL MP</td>
                                </tr>
                                <tr >
                                    <td>167</td>
                                    <td>Usha business soluations</td>
                                    <td>SHOP NO 11 NAKUL PATIL CHWAL OPP  NAKUL PATIL BANGLOW  DEVI CHOWK SHASTRI NAGAR DOMBIVLI WEST 421202</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>168</td>
                                    <td>VIJAY RAJ JINDAL & CO</td>
                                    <td>3027-BGURU KASHI MARGNEAR BUS STANDBHATINDAPUNJAB151001</td>
                                </tr>
                                <tr >
                                    <td>169</td>
                                    <td>VINAYAGAR ENTERPRISES</td>
                                    <td>42 NORTH RAJA STREET, DISTT. TIRUVALLUR, TAMIL NADU 602001</td>
                                </tr>
                                <tr className='bg-alt'>
                                    <td>170</td>
                                    <td>Vkt Financial Services</td>
                                    <td>UNIT NO. 3, 4 & 5, SCO – Block III & IV 3rd FLOOR, SPECIAL HUDA COMPLEX, SECTOR -19, FARIDABAD - 121002</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Recovery