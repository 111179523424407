import { Link } from 'react-router-dom';
import './App.css';


const Cookie = () => {

    return (
        <>
            <div className='h-20'></div>
            <section>
                <div className='heading-rounded m-auto text-center text-white font-bold py-4 w-11/12 md:w-3/5'>Privacy and Cookie Policy</div>
                <div className='container px-6 xl:px-0 xl:w-4/5 2xl:container mx-auto py-20'>
                    <p className='points mb-10'>Date: 27th March, 2023</p>
                    <p className='points mb-10'>This privacy policy (Privacy Policy) governs the collection, use, storage, disclosure, transfer or processing of personal information or sensitive personal information by International Asset Reconstruction Company Private Limited (IARC or Our or We) collected by IARC through its Website, Portal, email, phone call, or other interaction with agents of IARC in relation to the Services being offered by IARC. This Privacy Policy should be read along with the Terms & Conditions of Use of the Website and Portal available at www.iarc.co.in (Terms) and any terms capitalized but not defined under this Privacy Policy shall have the meaning set out in the Terms.</p>
                    <p className='points mb-10'>By visiting the Website or Portal, accessing any of the pages and/or providing information on the Website or Portal, or clicking ‘I Accept’, each visitor (“You” or “User”) agrees to the terms set out in this Policy. We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page and communicating to your registered mobile number and verified email/SMS, However, You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective as of the date specified at the top of this page.</p>
                    <p className='points mb-10'>The Privacy Policy will inform You about:</p>
                    <ul className='points mb-10 list-disc'>
                        <li>What personal information We collect and process and the manner in which personal information is collected</li>
                        <li>Why We collect and process such information and how We use this information, including how We share that information with third parties</li>
                        <li>Your rights and options</li>
                    </ul>
                    <p className='name mb-10'>Personal Information that We Collect and Process from You</p>
                    <p className='points mb-10'>The kinds of personal information which we may directly collect from you, including through our Website or Portal are:</p>
                    <ul className='points mb-10 list-disc'>
                        <li>name;</li>
                        <li>age;</li>
                        <li>email address;</li>
                        <li>telephone number;</li>
                        <li>postal address, home town, PIN Code;</li>
                        <li>location data, Your device’s Internet Protocol address, cookies, website usage data, information sent by Your browser or mobile device when You visit Our Website/Portal, and other tracking data as described below;</li>
                        <li>other personal information that You voluntarily provide to us, including via email, phone call.</li>
                    </ul>
                    <p className='points mb-10'>The kinds of sensitive personal information which you may be required to provide, for us to provide you with services are:</p>
                    <ul className='points mb-10'>
                        <li>[passwords;</li>
                        <li>financial information including credit and debit card numbers, payment instrument details, bank account details.]</li>
                    </ul>
                    <p className='points mb-10'>Such sensitive personal information  is used by us on the basis of your consent, which you provide us when you click on ["I agree"] at the time of accessing our Website or Portal or otherwise, for the collection, storage, use, processing, sharing and/ or transfer of such information, as applicable, in the manner provided under this Privacy Policy. [</p>
                    <p className='name mb-10'>Cookies and Usage Data: </p>
                    <p className='points mb-10'>We may also collect information that your browser sends whenever you visit our Website or Portal or when you access the Website or Portal by or through a mobile device (<strong>Usage Data</strong>).</p>
                    <p className='points mb-10'>We may collect information through the use of cookies, web beacons, pixel tags, analytics/ webmaster tools and/ or similar tracking technologies. A "cookie" is a string of information which assigns You a unique identifier that we store on Your device. Your browser then provides that unique identifier to use each time you submit a query to the Website or Portal. A web beacon or pixel tag is a tiny object or image embedded in a web page or email. They are used to track the number of users who have visited particular pages and viewed emails, and acquire other statistical data. They collect only a limited set of data, such as a cookie number, time and date of page or email view, and a description of the page or email on which they reside. Web beacons and pixel tags cannot be declined. However, You can limit their use by controlling the cookies that interact with them.</p>
                    <p className='points mb-10'>The following types of cookies may be used on the Website or Portal:</p>
                    <ol type='a' className='points mb-10 list-decimal'>
                        <li>Advertising Cookies: Advertising cookies are placed on Your device by advertisers and ad servers in order to display advertisements that are most likely to be of interest to you. These cookies allow advertisers and ad servers to gather information about your visits to the Website / Portal and other websites, alternate the ads sent to a specific computer, and track how often an ad has been viewed and by whom. These cookies are linked to a device and do not gather any personal information about you.</li>
                        <li>Analytics cookies: Analytics cookies monitor how Users reached the Website/Portal, and how they interact with and move around once on the Website/Portal. These cookies let Us know what features on the Website/Portal are working the best and what features can be improved.</li>
                        <li>Our cookies: Our cookies are ‘first-party cookies’ and can be either permanent or temporary. These are necessary cookies, without which the Website or Portal may not work properly or be able to provide certain features and functionalities. Some of these may be manually disabled in your browser, but may affect the functionality of the Website/Portal.</li>
                        <li>Personalization cookies: Personalization cookies are used to recognize repeat visitors to the Website/Portal. We use these cookies to record your browsing history, the pages you have visited, and your settings and preferences each time You visit the Website/Portal.</li>
                        <li>Security cookies: Security cookies help identify and prevent security risks. We use these cookies to authenticate users and protect user data from unauthorized parties.</li>
                        <li>Site management cookies:  Site management cookies are used to maintain Your identity or session on the Website/Portal so that You are not logged off unexpectedly, and any information You enter is retained from page to page. These cookies cannot be turned off individually, but you can disable all cookies in your browser.</li>
                        <li>Third party cookies: These may be placed on Your device when You visit the Website/Portal by third parties that run certain services We offer. These cookies allow the third parties to gather and track certain information about You.</li>
                    </ol>
                    <p className='points mb-10'>To the extent required by applicable law, we shall provide You with the option to either accept or refuse these cookies and know when a cookie is being sent to Your device. If You choose to refuse Our cookies, You may not be able to use some portions of Our Portal / Services.</p>
                    <p className='points mb-10'>We may receive and process information about your location. For example, with your consent, we may collect information about the specific location of your mobile device (for example, by using GPS or Bluetooth). We may also receive location information from you when you choose to share such information on our Services, including by associating your content with a location, or we may derive your approximate location from other information about you, including your IP address.</p>
                    <p className='points mb-10'>This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                    <p className='points mb-10'>When you access the Service by or through a mobile/tab device, this Usage Data may include information such as the type of mobile/tab device you use, your mobile device unique ID, the IP address of your mobile/tab device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.</p>
                    <p className='name mb-10'>Uses of Your Personal Information</p>
                    <p className='points mb-10'>IARC collects only such personal information that we believe to be relevant and would be required to understand You or Your loan account requirements. Personal information is collected from You for the following purposes:</p>

                    <ul className='points mb-10 list-disc'>
                        <li>For enabling, facilitating and improving the Services and Portal provided to you, and to contact you in relation to the same; for us to answer your questions and provide user support; to improve the Website / Portal experience, including in relation to troubleshooting, survey, testing, research, and statistical purposes and to send you information including confirmations, invoices and billing, updates, security alerts, administrative, and service messages;</li>
                        <li>For marketing and other promotional activities, including providing information regarding our services and personalized advertisements, based on your preferences;</li>
                        <li>For legal and compliance requirements;</li>
                        <li>Any other services as may be agreed by You.</li>
                    </ul>

                    <p className='points mb-10'>Further, by registering yourself at the Website/Portal, You authorize IARC to contact You (including via calls or SMS to the mobile number provided to Us) in order to communicate details of the Services You have consented to receive, provide details of information regarding your loan account, offering offers/ settlements etc. We provide all Users with the opportunity to opt-out of receiving non-essential (promotional, marketing-related) communications from Us on behalf of our partners, and from Us. If the You want to opt-out from receiving promotional SMS, please select the relevant unsubscribe option.</p>
                    <p className='name mb-10'>Disclosures and Transfer</p>
                    <p className='points mb-10'>All sensitive personal information that We collect is disclosed or transferred only under the following circumstances and to entities/individuals who adhere to the same levels of security standards as maintained by Us, to ensure the security, integrity, and privacy of Your sensitive personal information:</p>
                    <p className='points mb-10'>Third Parties: We will share such information with third parties who perform services on Our behalf or to the extent we may be providing any third-party services to You. We shall also transfer or disclose information to the extent the same is necessary to provide services to You, or to enable a transaction, credit reporting, required pursuant to applicable banking norms, law or pursuant to the terms and conditions applicable to such information as agreed to by You.</p>
                    <p className='points mb-10'>Disclosures To Affiliates/Group Companies: To the extent permitted under law, We and our affiliates/group companies (and Our staff) receive such information from and share such information with each other for (a) research and development in relation to the provision of the relevant product or service; (b) marketing and advertising the relevant product or service to you; (c) tailoring product or service offerings for your benefit; or (d) driving analytics on how our services are used and improving our services, our affiliates’ services and your experiences using them.</p>
                    <p className='points mb-10'>Mandatory Disclosures Or Transfers: As required by (a) applicable laws; or (b) any judicial or governmental proceeding.</p>
                    <p className='points mb-10'>Corporate Changes: In connection with the sale of our business or assets or an acquisition of our business by a third party or any other M&A transaction involving us.</p>
                    <p className='points mb-10'>Other reasons: We may, in good faith, transfer or disclose information to protect and defend the rights or property of IARC, to prevent or investigate possible wrongdoing in connection with the Portal or Services, or to protect the persona safety or users of the Services or the public.</p>
                    <p className='points mb-10'>The entities with whom your personal information may be shared is available <Link to='/recovery-agents' className='font-bold'>here</Link>.</p>
                    <p className='name mb-10'>Storage, Retention and Security of Your Personal Information</p>
                    <p className='points mb-10'>We strive to ensure the security, integrity and privacy of your personal information and to protect your personal information against unauthorized access or unauthorized alteration, disclosure or destruction. We adopt adequate measures to prevent unauthorized access to your personal information and have implemented reasonable security practices commensurate to the standards required under applicable laws.</p>
                    <p className='points mb-10'>Information collected by Us is stored on Our cloud servers.</p>
                    <p className='points mb-10'>All personal and sensitive personal information collected by us will be retained only as long as it is necessary for the purposes for which it is collected.</p>
                    <p className='name mb-10'>Grievance Officer</p>
                    <p className='points mb-10'>If you have any concerns or questions in relation to this Privacy Policy or would like to avail any of the rights provided to you under this Privacy Policy, you may write to our grievance officer at  <a href="mailto:grievanceofficer@iarc.co.in">grievanceofficer@iarc.co.in</a></p>
                    
                </div>
            </section>
        </>
    )
}
export default Cookie