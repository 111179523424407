import './App.css';
import { Link } from 'react-router-dom';
import React from 'react';

export default function Footer() {

	return (
		<>
			<footer className="bg-black">
				<div className='lg:w-full xl:w-4/5 2xl:container mx-auto'>
					<div>
						<div className="grid grid-cols-2 gap-8 px-6 py-8 md:grid-cols-4">
							<div>
								<h2 className="mb-6  text-grey-500 text-white font-bold">Get in touch</h2>
								<ul className="text-gray-500">
									<li className="mb-4">
										<Link to="/updates-and-notices" className="hover:underline">Updates And Notices</Link>
									</li>
									<li className="mb-4">
										<Link to="/policies" className="hover:underline">Policies & Procedure</Link>
									</li>
									<li className="mb-4">
										<Link to="/wilful-defaulter" className="hover:underline">Wilful Defaulters List</Link>
									</li>
									<li className="mb-4">
										<Link to="/recovery-agents" className="hover:underline">Recovery Agents</Link>
									</li>
									<li className="mb-4">
										<a className="hover:underline" href='/assets/pdf/Assets Possession_Update.pdf' target='_blank' rel="noreferrer">Secured assets possessed (SARFAESI Act)</a>
									</li>
								</ul>
							</div>
							<div>
								<h2 className="mb-6  text-grey-500 text-white font-bold">Quick Links</h2>
								<ul className="text-gray-500">
									<li className="mb-4">
										<a
											href="https://www.indiacode.nic.in/handle/123456789/2006?view_type=search"
											className="hover:underline"
											target='_blank'
											rel='noreferrer noopener'
										>
											SARFAESI Act
										</a>
									</li>
									<li className="mb-4">
										<a
											href="https://www.rbi.org.in/Scripts/BS_ViewMasDirections.aspx?id=12669"
											className="hover:underline"
											target='_blank'
											rel='noreferrer noopener'
										>
											RBI Circulars
										</a>
									</li>
									<li className="mb-6">
										<Link to="/social-responsibility-charter" className="hover:underline">Social Responsibility Charter</Link>
									</li>
								</ul>
							</div>
							<div>
								<h2 className="mb-6  text-grey-500 text-white font-bold">Disclaimer</h2>
								<ul className="text-gray-500">
									<li className="mb-4">
										<Link to="/disclaimer" className="hover:underline">Disclaimer</Link>
									</li>
									<li className="mb-4">
										<Link to="/privacy" className="hover:underline">Privacy and Cookie Policy</Link>
									</li>
									<li className="mb-4">
										<Link to="/terms" className="hover:underline">Terms and Conditions</Link>
									</li>
								</ul>
							</div>
							<div>
								<h2 className="mb-6  text-grey-500 text-white font-bold">Connect</h2>
								<ul className="text-gray-500">
									<li className="mb-4">
										<Link to="/grievance" className="hover:underline">Grievance Redressal</Link>
									</li>
									<li className="mb-4">
										<Link to="/contact-us" className="hover:underline">Contact Us</Link>
									</li>
									<li className="mb-4">
										<Link to="/careers" className="hover:underline" onClick={() => window.open("https://iarc.zohorecruit.in/jobs/Careers", "_blank")}>Careers</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className=' mr-5 ml-5 footbor'></div>
						<div className="px-4 py-6 md:flex bg-black md:items-center md:justify-between foot">
							<div>
								<span className="text-sm text-gray-500  font-bold">Copyrights © 2023 | All Rights Reserved</span>
							</div>
							<div className="flex mt-4 space-x-6 text-white sm:justify-center md:mt-0 text-sm">
								<p>CIN No. U74999DL2002PTC117357 | RBI  Registration number 006/2007</p>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
}
